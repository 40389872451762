import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const SiteImage = () => {
  const data = useStaticQuery(graphql`
    query {
      siteImage: file(relativePath: { eq: "cwh-icon.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 100
            layout: FULL_WIDTH)
        }
      }
    }
  `)

  if (data.siteImage) {
    return <GatsbyImage image={getImage(data.siteImage)} alt="The author and cook, Heather" />
  } else {
    return;
  }
}

export default SiteImage
