import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'


const SubscribeButton = () => {
  return (
    <button title="Subscribe on YouTube" >
      <a href="https://www.youtube.com/c/cookingthebookswithheather?sub_confirmation=1">
        <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
      </a>
    </button>
  )
}

export default SubscribeButton
