import React, { useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchengin } from '@fortawesome/free-brands-svg-icons'
import { navigate } from "gatsby-link";


const SearchBox = ({ showSubmitButton }) => {
  const [query, setQuery] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate('/search?query=' + query);
  }

  const handleChange = (e) => {
    setQuery(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="searchBox" className="input-icon">
        <FontAwesomeIcon icon={faSearchengin} />
      </label>
      <input id="searchBox" type="text" onChange={handleChange} placeholder="search" />
      {showSubmitButton && (
        <button type="submit" className="search-submit">Go</button>
      )}
    </form>
  )
}

export default SearchBox;
