import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitterSquare, faFacebookSquare, faInstagramSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'
import * as styles from "./footer.module.css"
import { siteMetadata } from "../../gatsby-config"
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons"

const Footer = () => (
  <footer>
    <div className={styles.subfooter}>
      <div>
        <ul className={styles.social}>
          <li key="facebook" className="facebook" title="FaceBook">
            <a href="https://www.facebook.com/cookingthebookswithheather">
              <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
            </a>
          </li>
          <li key="twitter" className="twitter" title="Twitter">
            <a href="https://twitter.com/cooking_heather">
              <FontAwesomeIcon icon={faTwitterSquare} size="2x" />
            </a>
          </li>
          <li key="youtube" className="youtube" title="YouTube">
            <a href="https://www.youtube.com/channel/UCpVeUULdNR4EME3csSxH_Aw">
              <FontAwesomeIcon icon={faYoutubeSquare} size="2x" />
            </a>
          </li>
          <li key="instagram" className="instagram" title="Instagram">
            <a href="https://www.instagram.com/cookingthebookswithheather/">
              <FontAwesomeIcon icon={faInstagramSquare} size="2x" />
            </a>
          </li>
          <li key="email" className="email" title="Email">
            <a href={'mailto:' + siteMetadata.author}>
              <FontAwesomeIcon icon={faEnvelopeSquare} size="2x" />
            </a>
          </li>
        </ul>
      </div>
      <div>
        <Link to="/privacy">Privacy Statement</Link> | <Link to="/copyright">Terms of Use</Link> | ©{new Date().getFullYear()} <a href="https://cookwithheather.com">cookwithheather.com</a>. All Rights Reserved.
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
