module.exports = {
  siteMetadata: {
    title: `Cook With Heather`,
    description: `Watch me cook from a variety of cookbooks, with tips and tricks to help you cook at home.`,
    author: `cookingthebookswithheather@gmail.com`,
    siteUrl: `https://cookwithheather.com`,
    menuLinks:[
      {
        name:'home',
        link:'/'
      },
      {
        name:'posts',
        link:'/posts'
      },
      {
        name:'about',
        link:'/about'
      },
      {
        name:'Deep Run Roots',
        link:'/drr'
      }
    ]
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "G-86733Q3ZSS",
          //"UA-186268482-1", // Google Analytics / GA
        ],
        gtagConfig: {
          anonymize_ip: true,
          cookie_expires: 0,
        },
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
          delayOnRouteUpdate: 0,
          respectDNT: false,
        },
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: `https://cookwithheather.com`,
      },
    },
    `gatsby-plugin-fontawesome-css`,
    `gatsby-plugin-sitemap`,
    `gatsby-plugin-react-helmet`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `posts`,
        path: `${__dirname}/src/posts/`,
        ignore: [`**/*.txt`], // ignore txt files
      },
    },
    {
      resolve: `gatsby-plugin-mdx`,
      options: {
        extensions: [`.mdx`, `.md`],
      },
    },
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/images/`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Cook With Heather`,
        short_name: `Heather`,
        start_url: `/`,
        background_color: `#CE0015`,
        theme_color: `#CE0015`,
        display: `standalone`,
        icon: `src/images/cwh-icon.jpg`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: 'gatsby-plugin-local-search',
      options: {
        // A unique name for the search index. This should be descriptive of
        // what the index contains. This is required.
        name: 'pages',

        // Set the search engine to create the index. This is required.
        // The following engines are supported: flexsearch, lunr
        engine: 'flexsearch',

        // Provide options to the engine. This is optional and only recommended
        // for advanced users.
        //
        // Note: Only the flexsearch engine supports options.
        engineOptions: 'speed',

        // GraphQL query used to fetch all data for the search index. This is
        // required.
        query: `
        {
          allMdx {
            nodes {
              fields {
                slug
              }
              frontmatter {
                source
                date(formatString: "MMMM D, Y")
                description
                keywords
                title
              }
            }
          }
        }
        `,

        // Field used as the reference value for each document.
        // Default: 'id'.
        ref: 'slug',

        // List of keys to index. The values of the keys are taken from the
        // normalizer function below.
        // Default: all fields
        index: ['title', 'slug', 'description', 'keywords', 'source'],

        // List of keys to store and make available in your UI. The values of
        // the keys are taken from the normalizer function below.
        // Default: all fields
        store: ['title', 'date', 'slug', 'description', 'keywords', 'source'],

        // Function used to map the result from the GraphQL query. This should
        // return an array of items to index in the form of flat objects
        // containing properties to index. The objects must contain the `ref`
        // field above (default: 'id'). This is required.
        normalizer: ({ data }) =>
          data.allMdx.nodes.map((node) => ({
            title: node.frontmatter.title,
            date: node.frontmatter.date,
            slug: node.fields.slug,
            description: node.frontmatter.description,
            keywords: node.frontmatter.keywords,
            source: node.frontmatter.source,
          })),
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
